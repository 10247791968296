import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative bg-gray-900 lg:mx-12 sm:mx-2 -mt-20 px-8 z-40`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-12`}
`;

const Heading = tw(SectionHeading)`w-full text-white text-2xl text-right py-4 `;

const Column = styled.div`
  ${tw`sm:w-full md:w-1/2 lg:w-1/3 lg:px-16 sm:px-1 py-8 md:py-6 flex flex-col`}

  .description {
    ${tw`mt-3 text-white leading-loose text-right text-sm`}
  }
`;

// SVGs with default values
const Icon1 = (
  <svg
    viewBox="0 0 24 24"
    fill="white"
    height="6em"
    width="6em"
  >
    <path d="M19.447 5.345A3.27 3.27 0 0016.29 3a3.293 3.293 0 00-3.277 3h-2.025a3.297 3.297 0 00-3.284-3 3.268 3.268 0 00-3.151 2.345l-2.511 8.368A1.027 1.027 0 002 14v1a5.006 5.006 0 005.001 5 5.003 5.003 0 004.576-3h.846a5.003 5.003 0 004.576 3A5.006 5.006 0 0022 14.999V14c0-.098-.015-.194-.042-.287l-2.511-8.368zM7.001 18A3.005 3.005 0 014 15c0-.076.017-.147.022-.222A2.995 2.995 0 017 12a3 3 0 013 3v.009A3.004 3.004 0 017.001 18zm9.998 0A3.004 3.004 0 0114 15.009V15a3 3 0 016-.001A3.005 3.005 0 0116.999 18z" />
  </svg>
);

const Icon2 = (
  <svg
    fill="none"
    stroke="#ffffff"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
    height="6em"
    width="6em"
  >
    <path d="M9 5v4" />
    <path d="M8 9 H10 A1 1 0 0 1 11 10 V14 A1 1 0 0 1 10 15 H8 A1 1 0 0 1 7 14 V10 A1 1 0 0 1 8 9 z" />
    <path d="M9 15v2M17 3v2" />
    <path d="M16 5 H18 A1 1 0 0 1 19 6 V12 A1 1 0 0 1 18 13 H16 A1 1 0 0 1 15 12 V6 A1 1 0 0 1 16 5 z" />
    <path d="M17 13v3M3 3v18h18" />
  </svg>
);

const Icon3 = (
  <svg
    viewBox="0 0 24 24"
    fill="#ffffff"
    height="6em"
    width="6em"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 7a8 8 0 110 16 8 8 0 010-16zm0 2a6 6 0 100 12 6 6 0 000-12zm0 1.5l1.323 2.68 2.957.43-2.14 2.085.505 2.946L12 17.25l-2.645 1.39.505-2.945-2.14-2.086 2.957-.43L12 10.5zM18 2v3l-1.363 1.138A9.935 9.935 0 0013 5.049V2h5zm-7-.001v3.05a9.935 9.935 0 00-3.636 1.088L6 5V2l5-.001z" />
  </svg>
);

const defaultData = [
  {
    icon: Icon1,
    title: "نهتم بادق التفاصيل",
    description:
      "نأخذ جميع التفاصيل بعين الاعتبار عندما نقدم خدماتنا لتلبي احتياجات عملائنا الفريدة لتساهم في فتح آفاق جديدة من النمو وتشكيل رؤية متجددة للمستقبل والوصول لها.",
  },
  {
    icon: Icon2,
    title: "تمكين المنظمات والافراد",
    description:
      "نأخذ جميع التفاصيل بعين الاعتبار عندما نقدم خدماتنا لتلبي احتياجات عملائنا الفريدة لتساهم في فتح آفاق جديدة من النمو وتشكيل رؤية متجددة للمستقبل والوصول لها.",
  },
  {
    icon: Icon3,
    title: "تحقيق نتائج ملموسة",
    description:
      "نأخذ جميع التفاصيل بعين الاعتبار عندما نقدم خدماتنا لتلبي احتياجات عملائنا الفريدة لتساهم في فتح آفاق جديدة من النمو وتشكيل رؤية متجددة للمستقبل والوصول لها.",
  },
];

export default ({ data = defaultData }) => {
  return (
    <Container>
      <ThreeColumnContainer>
        {data.map((item, index) => (
          <Column key={index}>
            {item.icon}
            <Heading>{item.title}</Heading>
            <div className="description">{item.description}</div>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
