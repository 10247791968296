import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import StyledHeader from "components/headers/light.js";
import FullWidthWithImage from "components/hero/FullWidthWithImage";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

// SVGs with default values
const FullEvaluation = (
  <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 24 24" height="6em" width="6em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"></path></svg>
);

const InternationalRecognition = (
  <svg stroke="white" fill="none" stroke-width="0" viewBox="0 0 24 24" height="6em" width="6em" xmlns="http://www.w3.org/2000/svg"><path stroke-width="2" d="M15 19H2V1h16v4m0 0a5 5 0 110 10 5 5 0 010-10zm-3 9v8l3-2 3 2v-8M5 8h6m-6 3h5m-5 3h2M5 5h2"></path></svg>);

const SpecialConsultations = (
  <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 24 24" height="6em" width="6em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm6 9.8a.9.9 0 0 1-.1.5l-2.1 4.9a1.34 1.34 0 0 1-1.3.8H9a2 2 0 0 1-2-2v-5a1.28 1.28 0 0 1 .4-1L12 5l.69.69a1.08 1.08 0 0 1 .3.7v.2L12.41 10H17a1 1 0 0 1 1 1z"></path></svg>
);

const GridContainer = styled.div`
  ${tw`grid grid-cols-6 gap-4`}
`;

const GridItem = styled.div`
${tw`bg-yellow-400 h-32 w-40 flex flex-col justify-center text-center shadow-md p-8 text-center font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-600 hover:text-white duration-300`}
clip-path: polygon(50% 0%, 85% 25%, 85% 75%, 50% 100%, 15% 75%, 15% 25%);

`;
// ${tw`bg-primary-200 rounded-lg shadow-md p-4 text-center font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-primary-500 hover:text-white  duration-300` } /* Add your common styles here */

const GridItem1 = styled(GridItem)`
  ${tw`col-start-3 col-span-2 -mb-12`}
`;


const GridItem2 = styled(GridItem)`
  ${tw`col-start-1 col-end-3 `}
`;

const GridItem3 = styled(GridItem)`
  ${tw`col-end-7 col-span-2`}
`;

const GridItem11 = styled(GridItem)`
  ${tw`col-start-3 col-span-2 -mt-12`}
`;

const GridItem22 = styled(GridItem)`
  ${tw`col-start-1 col-end-3`}
`;

const GridItem33= styled(GridItem)`
  ${tw`col-end-7 col-span-2`}
`;

const CertificatesGrid = () => (
  <GridContainer>
    <GridItem1>مقدمة من معهد الابتكار العالمي (GInI)</GridItem1>
    <GridItem2>مصادقة الابتكارات   (InE)</GridItem2>
    <GridItem3>مختبر الابتكار المعتمد (AInL)
    </GridItem3>
    <GridItem22>المنظمة المبتكرة المعتمدة (CInOrg)
    </GridItem22>
    <GridItem33>حاضنات الأعمال المعتمدة (ABI)
    </GridItem33>
    <GridItem11>مسرعات الأعمال المعتمدة (AAP)
    </GridItem11>   
  </GridContainer>
);

const defaultData = [
  {
    icon: FullEvaluation,
    title: "تقييم كامل للمؤسسة",
    description:
      "نقوم بتقييم المؤسسة بشكل كامل لتحديد الفجوات والتحديات والتحسينات المطلوبة للوصول للمستوي الاعلي من الابتكار والتميز.",
  },
  {
    icon: SpecialConsultations,
    title: "استشارات متخصصة",
    description:
      "يساعذك خبراء مركاز علر سد الفجوات وتحديد الاحتياجات وتقديم الاستشارات المتخصصة.",
  },
  {
    icon: InternationalRecognition,
    title: "إعتراف عالمي",
    description:
      "نساعدك في الحصول علي الاعتراف العالمي في مجال الابتكار والتميز.",
  },
];

export default () => (
  <AnimationRevealPage>
    <FullWidthWithImage
      heading={(
        <>
          <wbr />
          <br />
          <span tw="text-primary-500">
          اعتمادات كفاءة برامج الابتكار في المنظمات
          </span>
        </>
      )}
      LeftComponent={(<CertificatesGrid />)}
      description="نساعدك في وضع الخطط والارشادات التي تجعلك في المقدمة في مجال الابتكار والتميز والحصول علي الاعتراف العالمي."
      primaryActionText="تريد الحصول علي الاعتراف العالمي؟"
    />

    <MainFeature2 data={defaultData} />

    <Features
      isQ={true}
      subheading={<Subheading>عوامل تقييم الابتكار</Subheading>}
      heading="ماذا نقيس في عملية
        تقييم الابتكار؟
        "
      description="يتم إجراء التقييمات باستخدام أداة تقييم نضج الابتكار التي تم تطويرها بواسطة معهد الابتكار العالمي حصريًا لهذا الغرض. تقيّم هذه الأداة مستوى النضج الابتكاري للمنظمات بحسب العوامل والعناصر أدناه.
        "
      cards={[
        {
          // imageSrc: SupportIconImage,
          title: "التمويل",
          description: "هل توفر المنظمة الدعم المالي اللازم لإجراء استثمارات الابتكار؟            "
        },
        {
          // imageSrc: ShieldIconImage,
          title: "الإجراءات",
          description: "هل الهياكل والأدوار والعمليات والمقاييس المصممة لجعل برنامج الابتكار فعالاً؟            "
        },
        {
          // imageSrc: CustomerLoveIconImage,
          title: "التأسيس",
          description: "هل يمتلك برنامج الابتكار المؤسسي جميع الأسس اللازمة ليكون فعالاً؟            "
        },
        {
          // imageSrc: CustomerLoveIconImage,
          title: "الاستراتيجية",
          description: "هل لدى المنظمة إستراتيجية ابتكار مناسبة وفعالة؟            "
        },
        {
          // imageSrc: CustomerLoveIconImage,
          title: "النتائج",
          description: "هل تمكنت المنظمة في نهاية المطاف من تحقيق نتائج حقيقية وملموسة لها تأثير؟            "
        },
        {
          // imageSrc: CustomerLoveIconImage,
          title: "التعزيز والتحفيز            ",
          description: "هل تستخدم المنظمة وسائل فعالة لتعزيز برنامجها ودفع المشاركة المستمرة فيه؟            "
        },
        {
          // imageSrc: CustomerLoveIconImage,
          title: "المشاركة",
          description: "ما هي الطرق التي تستفيد بها المنظمة من المشاركة لدفع الابتكار التشاركي التصاعدي؟            "
        },
        {
          // imageSrc: CustomerLoveIconImage,
          title: "التمكين            ",
          description: "هل وضعت المنظمة العناصر اللازمة لتمكين برنامج الابتكار الخاص بها للقيام بعمله ومهامه؟            "
        },
      ]}
      linkText=""
    />    {/* Events Page header . */}


    <MainFeature HasHeader={true} subheading="كن مميزا واحصل علي اعتراف دولي"
      heading={(
        <>
          إحصل علي اعتراف عالمي في <span className="text-secondary-500"> الابتكار </span>
        </>
      )}
      description="الحصول على اعتراف عالمي بكفاءة الابتكار في المنظمات يعد تحقيقاً مهماً يعكس قدرة الشركة على التميز والتفوق في مجال الابتكار والإبداع.      "
      primaryButtonText="قم بالطلب الآن"
      primaryButtonUrl="/contact"
      data={[
        {
          icon: (
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="4em" width="4em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.99994 10V14L3.99994 15H12.9999L13.9999 14V5L13.7099 4.29L10.7099 1.29L9.99994 1H8V2H9.99994L12.9999 5V14H3.99994V10H2.99994ZM11 6H9V4H8V6H6V7H8V9H9V7H11V6ZM6 11H11V12H6V11Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7.06065 3.85356L4.91421 6L4.2071 5.29289L5.49999 4H2.5C2.10218 4 1.72064 4.15804 1.43934 4.43934C1.15804 4.72065 1 5.10218 1 5.5C1 5.89783 1.15804 6.27936 1.43934 6.56066C1.72064 6.84197 2.10218 7 2.5 7H3V8H2.5C1.83696 8 1.20107 7.73661 0.732233 7.26777C0.263392 6.79893 0 6.16305 0 5.5C0 4.83696 0.263392 4.20108 0.732233 3.73224C1.20107 3.2634 1.83696 3 2.5 3H5.49999L4.2071 1.70711L4.91421 1L7.06065 3.14645L7.06065 3.85356Z"></path></svg>
          ),
          title: "الطلب",
          slogan: "قم بطلب الخدمة",
          description: "أرسل لنا تفاصيل طلبك ليتمكن فريقنا من البدء في تقييم الطلب والتحضير للخطوات القادمة",
        },
        {
          icon: (
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="4em" width="4em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M6.36 18.78 6.61 21l1.62-1.54 2.77-7.6c-.68-.17-1.28-.51-1.77-.98l-2.87 7.9zM14.77 10.88c-.49.47-1.1.81-1.77.98l2.77 7.6L17.39 21l.26-2.22-2.88-7.9zM15 8c0-1.3-.84-2.4-2-2.82V3h-2v2.18C9.84 5.6 9 6.7 9 8c0 1.66 1.34 3 3 3s3-1.34 3-3zm-3 1c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>
          ),
          title: "التخطيط",
          slogan: "التحليل والتخطيط",
          description: "بعد التعرف علر التحديات يرسم خبراء مركاز الخطط والمخططات التنفيظية لرفع كفاءة الابتكار",
        },
        {
          icon: (
            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="4em" width="4em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path><path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5"></path><path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73"></path><path d="M6 9l12 0"></path><path d="M6 12l3 0"></path><path d="M6 15l2 0"></path></svg>
          ),
          title: "الاعتماد",
          slogan: "الحصول علر الاعتماد العالمي",
          description: "بعد الانتهاء من الخطط يتم البدء في تنفيذ الخطط والتحضير للحصول علي الاعتماد العالمي",
        },
      ]}
    />
    <FAQ subheading="الأسئلة الاكثر شويعا عن عملية التقييم"
      heading="لديك سؤال !؟"
      description="في الغالب ستجد إجابتك هنا ."
      faqs={[
        {
          question: "هل يتم التقييم لجاهزية الإبتكار خلال عملية التقييم ؟",
          answer:
            "نعم ،نقوم بقياس مدى استعداد المنظمة لإطلاق برنامج ابتكار رسمي وتحديد الثغرات الرئيسية التي يجب معالجتها قبل إطلاق برنامج الابتكار الرسمي. كما نساعد في تحديد أنشطة الابتكار الحالية والخطوات اللازمة للتحضير لإطلاق برنامج ناجح.            "
        },
        {
          question: "هل يتم تقييم الأداء الحالي للمنظمة خلال عملية التقييم ؟",
          answer:
            "نعم ، يتم تقييم الأداء الحالي للمنظمة وتحديد الفجوات والتحديات والتحسينات المطلوبة للوصول للمستوي الاعلي من الابتكار والتميز.            "
        },
        {
          question: "هل يتم التقييم التحضيري للابتكار ؟           ",
          answer:
            "نعم، نقوم بقياس مدى فرص النجاح للحصول على الاعتماد من خلال إرشادات خبرائنا التي تساعد المنظمة على اكتمال عناصر برنامج الابتكار، وتحديد مجالات التحسين، ومعالجة الثغرات لضمان نتيجة ناجحة وتصنيف أعلى.            "
        },
        {
          question: "هل يتم التقييم الرسمي للابتكار ؟           ",
          answer:
            "نعم ، يتم إجراء التقييم النهائي لمستوى النضج لبرنامج الابتكار المؤسسي. يحقق حصول المنظمة على الاعتماد الرسمي من معهد الابتكار العالمي تميزها عن منافسيها، وإظهار الالتزام بخلق قيمة وبناء تجارب استثنائية للمستفيدين.            "
        }
      ]}
    />
    <Footer />
  </AnimationRevealPage>
);
