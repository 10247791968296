import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative mb-12`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-80 bg-contain bg-no-repeat bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-primary-500`;
const Description = tw.p`mt-2 text-sm leading-loose`;
// const Link = tw.a`inline-block mt-4 text-xl text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;
const Link = styled.a`
  ${tw`block w-full mt-4 text-xl text-center text-white font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500 bg-primary-500 hover:bg-primary-400 py-2 px-4 rounded`}
`;


const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

// Styled component for the table container
const TableContainer = styled.div`
  ${tw`w-full overflow-hidden shadow-md`}
`;

// Styled component for the table
const Table = styled.table`
  ${tw`w-full border-collapse`}
`;

// Styled component for table headers
const TableHeader = styled.th`
  ${tw`py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-gray-700 border border-gray-300`}
`;

// Styled component for table rows
const TableRow = styled.tr`
  ${tw`bg-white`}
`;

// Styled component for table data cells
const TableData = styled.td`
  ${tw`py-2 px-4 text-sm text-gray-700 border border-gray-300`}
`;

const KeyValueTable = ({ data }) => (
  <TableContainer>
    <Table>
      <tbody>
        {data.map((pair, index) => (
          <TableRow key={index}>
            <TableHeader>{pair.key}</TableHeader>
            <TableData>{pair.value}</TableData>
          </TableRow>
        ))}
      </tbody>
    </Table>
  </TableContainer>
);

 
export default () => {
  const CertificatesDetailes = [
    {
      tag: "cina",
      image: "cina.webp",
      subtitle: "Certified Innovation Associate (CInA)®",
      title: "مساعد الابتكار المعتمد",
      description: "   لطلاب الجامعات - عمومًا أولئك الذين يقتربون من تاريخ تخرجهم - والذين أظهروا فهمًا واضحًا لبعض المواضيع الأساسية المتعلقة بابتكار الأعمال والعمل كمبتكر مساهم داخل بيئة الأعمال   ",
      data: [
        { key: 'عدد الأيام', value: "٤-٥" },
        { key: 'عددالساعات', value: "٣٠" },
        { key: 'عدد المتدربين', value: "٢٠ فأكثر" },
        { key: 'مقدمة من ', value: 'Global Innovation Institute (GInI)' },
      ]
    },
    {
      tag: "cinp",
      image: "cinp.png",
      subtitle: "Certified Innovation Professional (CInP)®",
      title: "محترف الابتكار المعتمد",
      description: " للمهنيين العاملين الذين يرغبون بالحصول على فهمًا متقدمًا للمواضيع الرئيسية المتعلقة بالابتكار وإدارة الابتكار.    ",
      data: [
        { key: 'عدد الأيام', value: "٤-٥" },
        { key: 'عددالساعات', value: "٣٠" },
        { key: 'عدد المتدربين', value: "٢٠ فأكثر" },
        { key: 'مقدمة من ', value: 'Global Innovation Institute (GInI)' },
      ]
    },
    {
      tag: "cdtp",
      image: "cdtp.webp",
      subtitle: "Certified Design Thinking Professional (CDTP)®",
      title: "محترف التفكير التصميمي المعتمد (CDTP) ",
      description: "اعتراف مهني للذين أظهروا فهمًا متقدمًا للمواضيع الرئيسية المتعلقة بالتصميم الذي يركز على الإنسان والتفكير في التصميم وعملية التفكير التصميمي وأساليب التصميم. ",
      data: [
        { key: 'عدد الأيام', value: "٤-٥" },
        { key: 'عددالساعات', value: "٣٠" },
        { key: 'عدد المتدربين', value: "٢٠ فأكثر" },
        { key: 'مقدمة من ', value: 'Global Innovation Institute (GInI)' },
      ]
    },
    {
      tag: "cins",
      image: "cins.webp",
      subtitle: "Certified Innovation Strategist (CInS)®    ",
      title: "استراتيجي الابتكار المعتمد (CInS)    ",
      description: "اعتراف لقادة الأعمال الذين أظهروا فهماً متقدماً للمواضيع الرئيسية المتعلقة باستراتيجية الابتكار، وابتكار نموذج الأعمال، والابتكار في تجربة العملاء، والترويج للفرص. ",
      data: [
        { key: 'عدد الأيام', value: "٤-٥" },
        { key: 'عددالساعات', value: "٣٠" },
        { key: 'عدد المتدربين', value: "٢٠ فأكثر" },
        { key: 'مقدمة من ', value: 'Global Innovation Institute (GInI)' },
      ]
    },
    {
      tag: "ccino",
      image: "ccino.webp",
      subtitle: "Certified Chief Innovation Officer (CCInO)®",
      title: "المدير التنفيذي للابتكار المعتمد (CCInO)",
      description: "اعتراف لكبار قادة المنظمات الذين أظهروا فهماً متقدماً للموضوعات الرئيسية المتعلقة بالابتكار المؤسسي، واستراتيجية الابتكار، وقيادة الابتكار على مستوى المنظمة .",
      data: [
        { key: 'عدد الأيام', value: "٤-٥" },
        { key: 'عددالساعات', value: "٣٠" },
        { key: 'عدد المتدربين', value: "٢٠ فأكثر" },
        { key: 'مقدمة من ', value: 'Global Innovation Institute (GInI)' },
      ]
    },
    {
      tag: "aina",
      image: "aina.webp",
      subtitle: "Authorized Innovation Assessor (AInA)®",
      title: " مقيم الابتكار المعتمد (AInA)",
      description: "يتم منح ترخيص مقيم الابتكار المعتمد لقادة الأعمال المخضرمين والمعتمدين ذوي الخبرة العملية الكبيرة وبخبرة مهنية لا تقل عن عشر سنوات، فضلاً عن حصوله على شهادة جامعية.",
      data: [
        { key: 'عدد الأيام', value: "٤-٥" },
        { key: 'عددالساعات', value: "٣٠" },
        { key: 'عدد المتدربين', value: "٢٠ فأكثر" },
        { key: 'مقدمة من ', value: 'Global Innovation Institute (GInI)' },
      ]
    }
  ];

  return (
    <Container >
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>
            الشهادات المهنية الاحترافية
          </HeadingTitle>
          <HeadingDescription>
            اكتساب المهارات اللازمة لتفعيل منظومة الابتكار
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {CertificatesDetailes.map((card, i) => (
            <Card id={card.tag} key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.image} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <KeyValueTable data={card.data} />
                <Link href="/contact">أطلب تسعيرة</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
