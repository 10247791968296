import React, { useState } from 'react';
import styled from 'styled-components';
import tw from "twin.macro";
import logo from "../../images/logo.png";
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative bg-gray-300 -mx-24 -mb-12 px-8 z-40`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col  md:items-stretch md:flex-row flex-wrap lg:mx-8 md:mx-6 sm:mx-1 py-12 md:py-12`}
`;

const Heading = tw(SectionHeading)`w-full text-primary-400 text-2xl text-right py-4 `;

const LargeColumn = styled.div`
  ${tw`sm:w-full md:w-2/5 lg:w-2/5 px-8 flex flex-col`}

  .description {
    ${tw`mt-3 mx-3 text-primary-400 leading-loose text-right text-lg`}
  }
`;

const Column = styled.div`
  ${tw`sm:w-full md:w-1/5 lg:w-1/5 px-16 flex flex-col`}

  .description {
    ${tw`mt-3 text-primary-400 leading-loose text-right text-lg`}
  }
`;

export const NavLink = tw.a`flex flex-row text-primary-500
  text-lg my-2
  font-bold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hocus:text-primary-500
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center text-primary-500  font-black border-b-0 text-4xl! mx-0! gap-4`};

  img {
    ${tw`w-16 mr-3`}
  }
`;

const SmallIcon = styled.img`
  ${tw`w-6 h-6`} /* Adjust the size as needed */
`;

const FormContainer = styled.form`
  ${tw`flex flex-row py-2 px-2 rounded mt-4 `}
`;

const FormInput = styled.input`
  ${tw`shadow appearance-none border border-primary-500 rounded w-full py-2 px-3 mx-4 text-primary-700 leading-tight focus:outline-none focus:shadow-outline`}
`;

const SubscribeButton = styled.button`
  ${tw`border-primary-500 border-2 text-primary-600 py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
`;

const DualElementContainer = styled.div`
${tw`flex shadow-md py-2 px-4 rounded mt-4 justify-between pb-6 mx-8`}
`;

const LeftElement = styled.div`
  color: #000000; /* Example text color */
  padding: 10px;
  margin:10px;
`;

const RightElement = styled.div`
  color: #000000; /* Example text color */
  padding: 10px;
  margin:10px;
`;

const Footer = () => {

  const [formData, setFormData] = useState({
    email: '',
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Submit formData to API endpoint
      const response = await fetch('https://us-central1-mrkaz-2030.cloudfunctions.net/api/maillist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle successful form submission
        alert(response.data)
      } else {
        // Handle error
        alert(response.data)
      }
    } catch (error) {
      alert('Error submitting form:', error.message);
    }
  };


  return (
    <Container>
      <ThreeColumnContainer>
        <LargeColumn>
          <LogoLink href="/" className="text-primary-400">
            <img src={logo} alt="logo" />
            مِـــركـــاز
          </LogoLink>
          <div className="description"> اشترك في نشرتنا البريدية لتبقى على اطلاع دائم بكل ما يتعلق بنشاطاتنا المستقبلية.
          </div>

          <FormContainer onSubmit={handleSubmit}>
            <FormInput
              id="email"
              name="email"
              type="email"
              required
              placeholder="بريدك الإلكتروني"
              value={formData.email}
              onChange={handleInputChange}
            />
            <SubscribeButton type="submit">إشتراك</SubscribeButton>
          </FormContainer>
        </LargeColumn>
        <Column>
          <Heading>خدماتنا </Heading>
          <NavLink href="#">{" "}التدريب {" "}</NavLink>
          <NavLink href="#">{" "}
            الفعاليات
          </NavLink>
          <NavLink href="#">{" "}
            التقييم
          </NavLink>
        </Column>
        <Column>
          <Heading> روابط أخرى</Heading>
          <NavLink href="\#">{" "} نبذة عننا  {" "}</NavLink>
          <NavLink href="#">{" "}
            تواصل معنا
          </NavLink>
        </Column>
        <Column>
          <Heading>تابعنا </Heading>
          <NavLink href="https://x.com/mrkazbusiness">{" "}
            <SmallIcon src='x.png' alt='x.com' /> @mrkazbusiness
            {" "}
          </NavLink>
          <NavLink href="https://www.linkedin.com/company/mrkaz">{" "}
            <SmallIcon src='linkedin.svg' alt='x.com' />
            @mrkaz
          </NavLink>
        </Column>
      </ThreeColumnContainer>
      <DualElementContainer>
        <LeftElement>
          العنوان: المملكة العربية السعودية، الرياض،
          info@mrkaz.org
        </LeftElement>
        <RightElement>
          © 2024 مركاز . جميع الحقوق محفوظة
        </RightElement>
      </DualElementContainer>
    </Container>
  );
};

export default Footer;
