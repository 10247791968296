import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>عن مركاز</Subheading>}
        heading="فريق متميز من الخبراء في عدة مجالات"
        buttonRounded={false}
        primaryButtonText="تواصل معنا"
        primaryButtonUrl="/contact"
        description="في مركاز لدينا مجموعة من الخبراء المتميزين لتنمية اعمالك وتطويرها"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>رؤيتنا</Subheading>}
        heading="بالعلم نسعي لتحقيق النجاح لك"
        description="نؤمن ان التعلم عملية مستمره تآتي ثمارها في بيئة العمل"
        buttonRounded={false}
        primaryButtonText="تعرف علي برامجنا التدريبية"
        primaryButtonUrl="/training"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>قيمنا</Subheading>}
        heading="القيم التي نعتمدها في عملنا"
        description="نؤمن ان تحقيق النجاح في الالتزام "
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "دائما متواجدين للمساعدة",
            description: "نحن دائما متواجدين لمساعدتك في اي وقت"
          },
          {
            imageSrc: ShieldIconImage,
            title: "فريق متميز",
            description: "خبراء في مجالاتهم ومتميزين في عملهم"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "الالتزام والتفاني",
            description: "نحن ملتزمين بتحقيق النجاح لك ولعملك"
          },
        ]}
        linkText=""
      />
      <TeamCardGrid 
        
      />
      <Footer />
    </AnimationRevealPage>
  );
};
