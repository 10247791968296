import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import GitHubButton from "react-github-btn";
import styled from "styled-components";

import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import Header, { LogoLink } from "./components/headers/light";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;
/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center lg:text-base   `;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");

  const downloadUrl = "/treact-ui.zip"
  React.useEffect(() => {
    var iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = downloadUrl
    document.body.appendChild(iframe);
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Container tw="-mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <StyledHeader collapseBreakpointClass="sm" />

          <HeroRow>
            <Heading>شكرا لك !</Heading>
            <Description tw="mt-12  text-primary-500 text-2xl">سنتواصل معك قريبا اذا لما تتلقي منا ردا يمكنك التواصل معنا من خلال  , <NavLink href="contact">الارقام المتواجدة هنا</NavLink>.</Description>
            <div tw="mt-12 text-center">
              تابعنا على منصات التواصل الاجتماعي الاحترافية ليصلك كل جديد عن اعمالنا في مركاز
              <div tw="mt-12 flex flex-col items-center">
                <a
                  href="https://x.com/mrkazbusiness"
                  data-size="large"
                  tw="bg-blue-400 p-4 rounded text-white"
                  data-show-count="true"
                >
              تابعنا على X 
                </a>
                <div tw="mt-8">
                  <a
                    href="https://www.linkedin.com/company/mrkaz"
                    data-size="large"
                    data-show-count="true"
                    tw="bg-gray-800 p-4 rounded text-white"
                    aria-label="Follow @owaiswiz on Linkedin"
                  >
                    تابعنا على لنكد إن
                  </a>
                </div>
              </div>
            </div>
          </HeroRow>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
