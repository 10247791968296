import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { StyledButton } from "components/hero/BackgroundAsImage";
import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../headers/light.js";
 
const StyledHeader = styled(Header)`
  ${tw`justify-between pt-8`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const Container = tw.div`relative bg-gray-300 z-20 -mx-8 -mt-8 px-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-10 md:py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const DescriptionColumn = tw(Column)`md:w-5/12 flex-shrink-0  md:h-auto relative text-primary-600`;
const StepsColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-6 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const DescriptionSection = tw.p`mt-4 mb-12 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-500`;


const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;
const Section = styled.section`
  ${tw`items-center py-16`}
`;

const TimlineContainer = styled.div`
  ${tw`justify-center max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6`}
`;


const TimelineContainer = styled.div`
  ${tw`w-full mx-auto lg:max-w-3xl`}
`;

const TimelineItemContainer = styled.div`
  ${tw`relative flex justify-between`}
`;

const StepIndicator = styled.div`
  ${tw`flex flex-col items-center w-10 mr-4 md:w-24`}
`;

const IndicatorCircle = styled.div`
  ${tw`flex items-center justify-center w-16 h-16 text-primary-400`}
`;

const IndicatorLine = styled.div`
  ${tw`w-1 h-full bg-primary-400`}
`;

const TimelineCard = styled.div`
  ${tw`relative flex-1  `}
`;

const CardContent = styled.div`
  ${tw`relative z-20 px-4 mb-20`}
`;

const Slogan = styled.p`
  ${tw`text-2xl font-bold text-secondary-800 dark:text-secondary-800`}
`;

const Description = styled.p`
  ${tw` dark:text-secondary-500`}
`;

const StepHighlighter = styled.div`
  ${tw`relative inline-block overflow-hidden bg-primary-200 px-2`}

  & > div {
    ${tw``}
  }
`;

const TimelineSection = ({ data }) => {
  return (
    <Section>
      <TimlineContainer>
        <TimelineContainer>
          {data.map((step, index) => (
            <TimelineItemContainer key={index}>
              <StepIndicator>
                <div>
                  <IndicatorCircle>
                    {step.icon}
                  </IndicatorCircle>
                </div>
             {index < data.length - 1 && <IndicatorLine></IndicatorLine>}
              </StepIndicator>
              <TimelineCard>
                <CardContent>
                  <StepHighlighter>
                    {step.title}
                  </StepHighlighter>
                  <Slogan>{step.slogan}</Slogan>
                  <Description>{step.description}</Description>
                </CardContent>
              </TimelineCard>
            </TimelineItemContainer>
          ))}
        </TimelineContainer>
      </TimlineContainer>
    </Section>
  );
};

export default ({
  subheading = "رحلتك الإبداعية تبدأ من هنا",
  heading = (
    <>
      أكتشف طريقتنا في تحسين <span className="text-secondary-500">الإبداع</span>
    </>
  ),
  description = "نهجنا بخطوات بسيطة وسهلة لتحسين الإبداع والتفكير الإبداعي لديك",
  primaryButtonText = "ابدأ الآن",
  primaryButtonUrl = "/contact",
  HasHeader = false,
  data = [
    {
      icon: (
        <svg
          viewBox="0 0 32 32"
          fill="currentColor"
          height="4em"
          width="4em"
        >
          <path
            fill="currentColor"
            d="M27.028 25.367a1.953 1.953 0 01-1.947 1.947H6.918a1.953 1.953 0 01-1.947-1.947V7.204c0-1.071.876-1.947 1.947-1.947h18.163c1.071 0 1.947.876 1.947 1.947v18.163zm0-13.18l-1.992-1.342a2.344 2.344 0 10-4.184.828l-4.307 6.586a2.333 2.333 0 00-2.059.488l-2.883-1.659c.015-.106.025-.213.025-.323a2.344 2.344 0 10-4.506.906l-2.153 1.997v2.125l3.198-2.967a2.334 2.334 0 002.717-.354l2.813 1.619a2.343 2.343 0 104.155-.986l4.277-6.54a2.332 2.332 0 002.065-.409l2.833 1.909v-1.878z"
          />
        </svg>
      ),
      title: "نستكشف الخلل",
      slogan: "نقوم بتقيم للاداء الحالي",
      description: "من خلال تقيم الاداء الحالي نستطيع تحديد الخلل والنقاط الضعيفة في مستوي الابتكار والابداع",
    },
    {
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="currentColor"
          height="4em"
          width="4em"
        >
          <path d="M6.91 5.5l2.3 2.29-1.42 1.42-2.29-2.3-2.29 2.3-1.42-1.42 2.3-2.29-2.3-2.29 1.42-1.42 2.29 2.3 2.29-2.3 1.42 1.42m13 13l-1.42-1.42-2.29 2.3-2.29-2.3-1.42 1.42 2.3 2.29-2.3 2.29 1.42 1.42 2.29-2.3 2.29 2.3 1.42-1.42-2.3-2.29m.49-11.67L17.18 11 15.6 9.73l1.17-1.5a9.08 9.08 0 00-6.66 5.62A4.5 4.5 0 117.5 13a4 4 0 01.78.08 11.27 11.27 0 018.15-6.82L15 5.18l1.27-1.58M10 17.5A2.5 2.5 0 107.5 20a2.5 2.5 0 002.5-2.5z" />
        </svg>
      ),
      title: "ترتيب الاحتياجات",
      slogan: "معرفة الفجوات العملية",
      description: "نضع ايدينا علي الفجوات بين الخطط وتنفيـذها ونقوم بتحديد الاحتياجات العملية للتحسين والتطوير",
    },
    {
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="currentColor"
          height="4em"
          width="4em"
        >
          <path d="M12 20l-5 2 5-11 5 11-5-2M8 2h8v3h6v2h-6v3H8V7H2V5h6V2m2 2v4h4V4h-4z" />
        </svg>
      ),
      title: "نرسم المستقبل",
      slogan: "تحديد الرؤي والخطط",
      description: "نعمل علي وضع الخطط المناسبه للمؤسسه بناء علي الفجوات التي تم تحديدها ونقوم بتحديد الرؤي والاهداف المستقبلية للمؤسسة والتي تساعد علي تحسين الابداع والابتكار",
    },
    {
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="currentColor"
          height="4em"
          width="4em"
        >
          <path d="M21.92 6.62a1 1 0 00-.54-.54A1 1 0 0021 6h-5a1 1 0 000 2h2.59L13 13.59l-3.29-3.3a1 1 0 00-1.42 0l-6 6a1 1 0 000 1.42 1 1 0 001.42 0L9 12.41l3.29 3.3a1 1 0 001.42 0L20 9.41V12a1 1 0 002 0V7a1 1 0 00-.08-.38z" />
        </svg>
      ),
      title: "نبدأ التنفيذ",
      slogan: "تحقيق الغاية وإحداث التأثير.",
      description: "يتم تصميم البرامج ومؤامتها لاحتياجات المنظمة وتنفيذها عبر خطة زمنية ومؤشرات أداء تضمن تحقيق النتائج والأثر.",
    },
  ],
}) => {
  return (
    <Container id="roadmap" style={{
      borderTop: " 3px solid transparent;",
      botrderTop: "3px solid transparent;",
    }}>
      
      <TwoColumn >
        <DescriptionColumn>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <DescriptionSection>{description}</DescriptionSection>
            <StyledButton href={primaryButtonUrl}>
              <span></span>
              <span></span>
              <span>
                {primaryButtonText}
              </span>
            </StyledButton>
          </TextContent>
        </DescriptionColumn>
        <StepsColumn>
          <TimelineSection data={data} />
        </StepsColumn>
      </TwoColumn>
    </Container>
  );
};

 