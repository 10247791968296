import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line 
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/2 lg:px-6 md:px-4 sm:px-1 flex`}
`;
const Card = styled.a`
  ${tw`flex flex-col sm:flex-row w-full items-center px-6 py-5 border-2 border-solid border-primary-500 rounded-lg mt-12 transition duration-300 ease-in-out transform hover:shadow-lg hover:-translate-y-1 hover:border-primary-700`}
  
  box-shadow: 8px 8px 0 0 #fff, 16px 16px 0 0 #ddd;
  .imageContainer {
    ${tw`p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-12 h-12 rounded-full`}
    }
    /* Add double shadow */
  }

  .textContainer {
    ${tw`mt-4 sm:mt-0 sm:ml-6 text-center sm:text-right`}
  }

  .title {
    ${tw`mt-2 font-bold text-2xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 text-primary-700 leading-loose`}
  }
`;




const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */
  // <NavLink href="/training">{" "}التدريب {" "}</NavLink>
  // <NavLink href="/events">{" "}
  //   الفعاليات
  // </NavLink>
  // <NavLink href="/consultancy">{" "}
  //   الإستشارات
  // </NavLink>
  // <NavLink href="/evaluation">{" "}
  //   التقييم
  // </NavLink>

  const cards = [
    {
      imageSrc: <svg
        viewBox="0 0 24 24"
        fill="text-primary-100"
        height="6em"
        width="6em"
      >
        <path d="M2 3h8a2 2 0 012-2 2 2 0 012 2h8v2h-1v11h-5.75L17 22h-2l-1.75-6h-2.5L9 22H7l1.75-6H3V5H2V3m3 2v9h14V5H5m6.85 6.85a.49.49 0 01-.35.15.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5c.14 0 .26.06.35.15l1.4 1.39c.32.32.64.64.64.96 0 .32-.32.64-.64.96l-1.4 1.39z" />
      </svg>
      ,
      title: "التدريب",
      href:"/training",
      description: "نعمل جاهدين علي اعداد جيل من المبدعين والمتميزين في مجالاتهم من خلال تقديم برامج تدريبية متميزة ومتنوعة تتناسب مع احتياجات السوق والمجتمع وتساهم في تطوير الأداء الوظيفي للمتدربين وتحقيق أهدافهم المهنية والشخصية."
    },
    {
      imageSrc: <svg
        fill="text-primary-100"
        viewBox="0 0 16 16"
        height="6em"
        width="6em"
      >
        <path d="M3.5 0a.5.5 0 01.5.5V1h8V.5a.5.5 0 011 0V1h1a2 2 0 012 2v11a2 2 0 01-2 2H2a2 2 0 01-2-2V3a2 2 0 012-2h1V.5a.5.5 0 01.5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM11.5 7a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z" />
      </svg>
      , title: "الفعاليات", 
      href:"/events",
      
      description: "نقوم بتنظيم الفعاليات التي تنمي روح الفريق والابتكار الجماعي وتناغم وتيرة العمل الجماعي في مؤسستك  ."
    }, 
    {
      imageSrc: <svg
        fill="text-primary-100"
        viewBox="0 0 16 16"
        height="6em"
        width="6em"
      >
        <path d="M2 10h3a1 1 0 011 1v3a1 1 0 01-1 1H2a1 1 0 01-1-1v-3a1 1 0 011-1zm9-9h3a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1V2a1 1 0 011-1zm0 9a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1h-3zm0-10a2 2 0 00-2 2v3a2 2 0 002 2h3a2 2 0 002-2V2a2 2 0 00-2-2h-3zM2 9a2 2 0 00-2 2v3a2 2 0 002 2h3a2 2 0 002-2v-3a2 2 0 00-2-2H2zm7 2a2 2 0 012-2h3a2 2 0 012 2v3a2 2 0 01-2 2h-3a2 2 0 01-2-2v-3zM0 2a2 2 0 012-2h3a2 2 0 012 2v3a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm5.354.854a.5.5 0 10-.708-.708L3 3.793l-.646-.647a.5.5 0 10-.708.708l1 1a.5.5 0 00.708 0l2-2z" />
      </svg>
      , title: "التقييم",
      href:"/evaluation",
      description: "نقوم بقياس روح الابتكار في فريق العمل وتصنيف التححديات الحالية"
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>خدماتنا الاحترافيه وسيلتك للنجاح </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card href={card.href}>
              <span className="imageContainer">
                {card.imageSrc || defaultCardImage}
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
