import React from "react";
import tw from "twin.macro";
import styled, { keyframes } from "styled-components";
import { TypeAnimation } from 'react-type-animation';

import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:text-primary-400`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-10 bg-center bg-cover`}
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-8 pb-20 px-4 flex justify-between items-center flex-col lg:flex-row`;

const LeftColumn = tw.div`w-full lg:w-3/5 flex flex-col items-start`;
const RightColumn = tw.div`w-full lg:w-2/5 flex flex-col mt-16 lg:mt-0 lg:pl-8`;

const CTABottomContainer = tw.div`flex flex-row justify-between mt-10 sm:block sm:items-start`;

const Heading = styled.h1`
  ${tw`text-6xl text-primary-400   leading-none mt-10 sm:mt-16`}
`;

const SlantedBackground = styled.span`
  ${tw`relative px-4 bg-primary-400 text-3xl py-2`}
  `;

const Notification = tw.span`inline-block my-2 pl-3 py-1 text-primary-400 text-xl leading-relaxed`;

const bounceAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

// Styled component with Tailwind CSS
const AnimatedImage = styled.img`
  ${tw`shadow-sm opacity-50 scale-50`}
  animation: ${bounceAnimation} 3s infinite;
`;

export const StyledButton = styled.a`
  ${tw`relative mx-4 px-6 py-3 font-bold text-black`}

  span:first-child {
    ${tw`absolute inset-0 w-full h-full transition duration-300 ease-out transform -translate-x-2 -translate-y-2 bg-primary-200 hover:translate-x-0 hover:translate-y-0`}
  }

  span:nth-child(2) {
    ${tw`absolute inset-0 w-full h-full border-4 border-black`}
  }

  span:last-child {
    ${tw`relative`}
  }
`;



export default () => {
  return (
    <Container className="background-container" id="id">
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader />
        <TwoColumn>
          <LeftColumn>

            <Heading>
              <div class="bg-white text-primary-400 p-4">
                <div class="relative">
                  <span class="bg-white text-3xl text-black px-1 border-b-2 border-r-2 border-black">
                    نمكن ونبتكر لمستقبل مزدهر
                  </span>
                </div>
              </div>

            </Heading>

            <Notification>نسعي في مركاز إلي تعزيز كفاءة وبناء منظومة الابتكار داخل الهيئات، ودعم الكوادر لريادة مساعي الابتكار والأعمال المنسقة لإيجاد قيمة اقتصادية وأثر اجتماعي مستدام.</Notification>
            <CTABottomContainer>
              <StyledButton href="\#roadmap">
                <span></span>
                <span></span>
                <span>أكتشف طريقك إلي النجاح</span>
              </StyledButton>

              <StyledButton href="/contact">
                <span></span>
                <span></span>
                <span>تواصل معنا</span>
              </StyledButton>

            </CTABottomContainer>

          </LeftColumn>
          <RightColumn>
            <div>
              <AnimatedImage
                src="intro.png"
                alt="mrkaz logo"
                className="w-48"
              />
            </div>
            {/* <img alt="s" src="logo.png" /> */}

            <SlantedBackground>
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  ' استشارات متخصصة في الابتكار',
                  2000, // wait 2s before replacing "Mice" with "Hamsters"
                  'خطط استراتيجية للنمو',
                  2000,
                  'نشر ثقافة الإبتكار',
                  2000,
                  'تمكين الأفراد والمنظمات من الإبتكار',
                  2000
                ]}
                wrapper="span"
                speed={30}
                style={{ display: 'inline-block', color: 'white' }}
                repeat={Infinity}
              />
            </SlantedBackground>
          </RightColumn>
        </TwoColumn>

      </HeroContainer>
    </Container>
  );
};
