import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative bg-gray-900 lg:mx-8 sm:mx-2 -mt-32 px-8 z-40 shadow-md`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-12`}
`;

const Heading = tw(SectionHeading)`w-full text-white text-2xl text-center py-4 `;

const Column = styled.div`
${tw`sm:w-full md:w-1/3 lg:w-1/4 flex flex-col items-center`} /* Apply width, flex layout, and center align */

  .description {
    ${tw`mt-3 text-white leading-loose text-right text-sm px-4`}
  }
`;

const BulletListContainer = styled.ul`
  ${tw`list-disc ml-6`} /* Apply Tailwind CSS classes for list style */
`;

const ListItem = styled.li`
  ${tw`mb-2 text-white text-xl`} /* Apply Tailwind CSS classes for margin */
`;

// SVGs with default values
const Icon1 = (
  <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 24 24" height="6em" width="6em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="m16.24 11.51 1.57-1.57-3.75-3.75-1.57 1.57-4.14-4.13c-.78-.78-2.05-.78-2.83 0l-1.9 1.9c-.78.78-.78 2.05 0 2.83l4.13 4.13L3 17.25V21h3.75l4.76-4.76 4.13 4.13c.95.95 2.23.6 2.83 0l1.9-1.9c.78-.78.78-2.05 0-2.83l-4.13-4.13zm-7.06-.44L5.04 6.94l1.89-1.9L8.2 6.31 7.02 7.5l1.41 1.41 1.19-1.19 1.45 1.45-1.89 1.9zm7.88 7.89-4.13-4.13 1.9-1.9 1.45 1.45-1.19 1.19 1.41 1.41 1.19-1.19 1.27 1.27-1.9 1.9zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34c-.47-.47-1.12-.29-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
);

const Icon2 = (
  <svg
    fill="none"
    stroke="#ffffff"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
    height="6em"
    width="6em"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 21h18"></path><path d="M5 21v-12l5 4v-4l5 4h4"></path><path d="M19 21v-8l-1.436 -9.574a.5 .5 0 0 0 -.495 -.426h-1.145a.5 .5 0 0 0 -.494 .418l-1.43 8.582"></path><path d="M9 17h1"></path><path d="M14 17h1"></path>

  </svg>
);

const Icon3 = (
  <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 640 512" height="6em" width="6em" xmlns="http://www.w3.org/2000/svg"><path d="M256 336h-.02c0-16.18 1.34-8.73-85.05-181.51-17.65-35.29-68.19-35.36-85.87 0C-2.06 328.75.02 320.33.02 336H0c0 44.18 57.31 80 128 80s128-35.82 128-80zM128 176l72 144H56l72-144zm511.98 160c0-16.18 1.34-8.73-85.05-181.51-17.65-35.29-68.19-35.36-85.87 0-87.12 174.26-85.04 165.84-85.04 181.51H384c0 44.18 57.31 80 128 80s128-35.82 128-80h-.02zM440 320l72-144 72 144H440zm88 128H352V153.25c23.51-10.29 41.16-31.48 46.39-57.25H528c8.84 0 16-7.16 16-16V48c0-8.84-7.16-16-16-16H383.64C369.04 12.68 346.09 0 320 0s-49.04 12.68-63.64 32H112c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h129.61c5.23 25.76 22.87 46.96 46.39 57.25V448H112c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
);

const Icon4 = (
  <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 512 512" height="6em" width="6em" xmlns="http://www.w3.org/2000/svg"><path d="M96.37 32l-48 64h32v64h32.03V96h32L96.37 32zm160.03 0l-48 64h32v64h32V96h32l-48-64zm160 0l-48 64h32v64h32V96h32l-48-64zM96.37 192c-17.2 0-32.5 18.4-32.5 42.5 0 12.4 4.1 23.4 10.3 31l6.2 7.8-9.9 1.5c-9.4 1.5-15.8 6-21.1 13.1-5.3 7.1-9.2 16.9-11.6 28.4-4.7 20.9-4.8 46.6-4.8 69h25.9l6.3 98h59.73l7.2-98h27.3c-.1-22.1-1.2-47.5-6.3-68.3-2.8-11.3-6.7-21.2-12-28.4-5.1-7.1-11.4-11.6-19.9-13.2l-9.7-1.8 6.4-7.5c6.4-7.8 10.8-18.9 10.8-31.6 0-22.7-13.7-40.4-29.13-42.5h-3.2zm160.03 0c-17.2 0-32.5 18.4-32.5 42.5 0 12.4 4.1 23.4 10.3 31l6.2 7.8-9.9 1.5c-9.4 1.5-15.8 6-21.1 13.1-5.3 7.1-9.2 16.9-11.6 28.4-4.7 20.9-4.8 46.6-4.8 69h25.9l6.3 98h59.7l7.2-98h27.3c-.1-22.1-1.2-47.5-6.3-68.3-2.8-11.3-6.7-21.2-12-28.4-5.1-7.1-11.4-11.6-19.9-13.2l-9.7-1.8 6.4-7.5c6.4-7.8 10.8-18.9 10.8-31.6 0-22.7-13.7-40.4-29.1-42.5h-3.2zm160 0c-17.1 0-32.4 18.4-32.4 42.5 0 12.4 4.1 23.4 10.3 31l6.2 7.8-9.9 1.5c-9.4 1.5-15.9 6-21.2 13.1-5.3 7.1-9.2 16.9-11.6 28.4-4.7 20.9-4.8 46.6-4.8 69h25.9l6.4 98h59.6l7.3-98h27.2c-.1-22.1-1.2-47.5-6.2-68.3-2.8-11.3-6.8-21.2-12.1-28.4-5.1-7.1-11.4-11.6-19.8-13.2l-9.7-1.8 6.3-7.5c6.4-7.8 10.8-18.9 10.8-31.6 0-22.7-13.7-40.4-29.1-42.5h-3.2z"></path></svg>
)
const defaultData = [
  {
    icon: Icon1,
    title: "التصميم",
    items: ["تحديد المعايير التدريبية", "بناء المحتوي التدريبي", "تصميم رحلة البرنامج التدريبي"]
  },
  {
    icon: Icon2,
    title: "التنفيذ",
    items: ["بناء الخطة التشغيلية", "إقامة الدورات التدريبية"]
  },
  {
    icon: Icon3,
    title: "القياس",
    items: [" قياس رضا المتدرب"]
  },
  {
    icon: Icon4,
    title: "التطوير",
    items: [" تطوير البرنامج التدريبي"]
  },
];

const StyledBulletList = ({ items }) => (
  <BulletListContainer>
    {items.map((item, index) => (
      <ListItem key={index}>{item}</ListItem>
    ))}
  </BulletListContainer>
);

export default ({ data = defaultData }) => {
  return (
    <Container>
      <ThreeColumnContainer>
        {data.map((item, index) => (
          <Column key={index}>
            {item.icon}
            <Heading>{item.title}</Heading>
            <div className="description"> {item.description}

            </div>
            <StyledBulletList items={item.items} />
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
