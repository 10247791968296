import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import styled from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import FullWidthWithImage from "components/hero/FullWidthWithImage";
import TrainingProcess from "components/features/TrainingProcess";
import MainFeature1 from "components/features/TwoColWithButton.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import TrainingHero from "components/hero/TrainingHero";
import TrainingCeertificates from "components/features/TrainingCeertificates";

const Heading = tw(SectionHeading)``;
const Subheading2 = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;

const Certificates = () => (
  <ContentWithPaddingXl>
    <Subheading2>الدورات التدريبية</Subheading2>
    <Heading>الدورات التدريبية الاحترافية المعتمدة</Heading>
    <Description>معتمدة من المعهد العالمي للابتكار . معتمدة من المؤسسة العامة للتدريب التقني والمهني</Description>
    <img alt="certificates" src="/certificats.png" />
  </ContentWithPaddingXl>
)

export default () => (
  <AnimationRevealPage>
    <TrainingHero />
    <TrainingCeertificates />
    <TrainingProcess />
    <Footer />
  </AnimationRevealPage>
);