import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-80 bg-contain bg-no-repeat bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);

const Details = tw.div`mt-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Subtitle2 = tw.div`font-bold mt-8 text-xl tracking-wide text-primary-900 underline`;
const Title = tw.h4`text-3xl font-bold text-primary-500`;
const Description = tw.p`mt-2 text-lg leading-loose`;
// const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const Link = styled.a`
  ${tw`inline-block   mt-4 text-xl text-center text-white font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500 bg-primary-500 hover:bg-primary-400 py-2 px-4 rounded`}
`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const ListContainer = styled.ul`
  ${tw`list-none`}
`;

// Styled component for the list items
const ListItem = styled.li`
  ${tw`mb-2 pr-6 relative`}
  &::before {
    content: '';
    ${tw`absolute h-2 w-2 bg-primary-500 rounded-full`}
    right: 0rem; /* Adjust the position of the bullet */
    left: 2rem;
    top: 0.6rem; /* Adjust the position of the bullet */
  }
`;

const UnorderedList = ({ items }) => (
  <ListContainer>
    {items.map((item, index) => (
      <ListItem key={index}>{item}</ListItem>
    ))}
  </ListContainer>
);

export default () => {
  const cards = [
    {
      imageSrc:
        "hackathons.jpeg",
      subtitle: "(Innovation Hackathons)",
      title: "هاكاثونات الابتكار",
      description:
        "هي فعاليات تجمع مجموعة من المبدعين والمطورين والمصممين والمهندسين والأشخاص المهتمين بالابتكار وحل المشكلات. تهدف هذه الفعاليات إلى تشجيع التفكير الإبداعي وتعزيز التعاون وتطوير حلول جديدة لمشكلات محددة من خلال عمل المشاركون سويًا على تحليل المشكلة وتطوير فكرة مبتكرة وتصميم وتنفيذ نموذج أولي أو حل برمجي في فترة زمنية محدودة تتراوح بين يوم واحد إلى ثلاث أيام.        ",
      clients: [
        "المنظمات التي ترغب بإشراك المجتمع في حل تحدياتها والخروج بحلول مبتكرة قابلة للتطبيق على الواقع.",
        "المنظمات التي ترغب في  إشراك عملائها في تحسين وتطوير الخدمات لديها.",
        "المنظمات التي ترغب في تفعيل دور استشراف المستقبل لديها للخروج بتقنيات حديثة تطور من خدماتها ومنتجاتها ."
      ]
    },
    {
      imageSrc:
        "design.jpeg",
      subtitle: "(Design Thinking Workshops)",
      title: "ورش عمل التفكير التصميمي      ",
      description:
        "تهدف ورش عمل التفكير التصميمي على صناعة حلول مبتكرة تتمحور حول الانسان، وهي عملية تركز على تحليل المشكلة وتفهم الاحتياجات والتوقعات للمستخدمين، وتطوير حلول مبتكرة قابلة للتطبيق على أرض الواقع        ",
      clients: [
        "المنظمات التي ترغب في رفع مستوى رضا العميل لديها من خلال تصميم خدمات جديدة",
        "المنظمات التي ترغب في تحسين جودة الخدمات لديها من خلال إعادة تصميم خدمات حالية وجعلها خدمات تواكب إحتياج و تطلعات العميل",
        "المنظمات التي ترغب في رفع كفاءة وزيادة الدخل من خلال تصميم منتج جديد",
      ]
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>
            للابتكار طريقة نعرفها جيداَ في مركاز
          </HeadingTitle>
          <HeadingDescription>
            استكشف فعاليتنا حيث ابداعية الانتاج والابتكار
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>

                <Subtitle2>
                  العملاء المستفيدين من الخدمة :
                </Subtitle2>
                <UnorderedList items={card.clients} />
                <Link href='/contact'>لديك فعالية ؟ تواصل معنا </Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
