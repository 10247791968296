import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import styled from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import FullWidthWithImage from "components/hero/FullWidthWithImage";
import VerticalWithAlternateImageAndText from "components/features/VerticalWithAlternateImageAndText";
import ContactUsForm from "components/forms/SimpleContactUs.js";

export default () => (
  <AnimationRevealPage>
    <FullWidthWithImage LeftComponent={<img src="ideas.jpg" />} />
    <VerticalWithAlternateImageAndText />
    <Footer />
  </AnimationRevealPage>
);
