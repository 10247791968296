import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import MainFeature1 from "components/features/TwoColWithButton.js";

import Header, { LogoLink } from "../headers/light.js";
import { StyledButton } from "./BackgroundAsImage.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`; const Subheading = tw.span`uppercase tracking-wider text-sm`;


const Container = tw.div`relative -mt-8 -mx-8 pt-8 bg-white`;
const TwoColumn = tw.div`pt-2 pb-20 px-12 flex justify-between items-start flex-col lg:flex-row`;
// const LeftColumn = tw.div``;
const LeftColumn = tw.div`
   mr-8 lg:w-1/2   
`;

const RightColumn = styled.div`
  ${tw`lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-4 lg:mt-2 lg:mb-24 flex flex-col sm:items-center lg:items-stretch pt-12`;
const Heading = tw.h1`text-6xl text-primary-500 sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-lg xl:text-lg leading-loose`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`} 
`;

const GridContainer = styled.div`
  ${tw`grid grid-rows-3 grid-cols-6 grid-flow-col`}
`;

const GridItem = styled.div`
${tw`bg-primary-100 h-32 w-40 flex flex-col justify-center text-center shadow-md p-8 text-center font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-600 hover:text-white duration-300`}
clip-path: polygon(50% 0%, 85% 25%, 85% 75%, 50% 100%, 15% 75%, 15% 25%);
`;
// ${tw`bg-primary-200 h-32 w-32 text-lg flex flex-col justify-center text-center rounded-full shadow-md p-4 text-center font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-primary-500 hover:text-white duration-300`}

const GridItem3 = styled(GridItem)`
  ${tw`col-start-5 row-start-1 col-span-2`}
`;

const GridItem1 = styled(GridItem)`
  ${tw`col-start-1 row-start-1 col-end-3 `}
`;

const GridItem4 = styled(GridItem)`
  ${tw`col-start-2 row-start-2 col-span-2`}
`;
const GridItem5 = styled(GridItem)`
  ${tw`grid-rows-2 row-start-2 col-start-4 col-end-7 col-span-2`}
`;

const GridItem2 = styled(GridItem)`
  ${tw`grid-rows-2 row-start-1 col-start-3 col-end-7 col-span-2`}
`;

const GridItem6 = styled.div`
  ${tw`row-start-3 col-start-3 col-end-7 col-span-2 pt-12`}
`;

const OrgGridContainer = styled.div`
  ${tw`grid grid-rows-2 md:grid-cols-3 sm:grid-cols-1 gap-2`}
`;

const OrgGridItem = styled.a`
  ${tw`flex flex-col justify-center text-center text-center font-bold m-1 md:m-1 sm:m-8 text-center font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:text-white duration-300`}
`;

const TrainingIndividualsTopics = () => (
  <GridContainer>
    <GridItem1>برنامج حاضنات الأعمال  </GridItem1>
    <GridItem2> تصميم النموذج الأولي
    </GridItem2>
    <GridItem3>برنامج التفكير التصميمي </GridItem3>
    <GridItem4>مقدمة وتعريف  عن الابتكار </GridItem4>
    <GridItem5> أدوات ومنهجيات الابتكار
    </GridItem5>
    <GridItem6>
      <StyledButton href="/contact">
        <span></span>
        <span></span>
        <span>
          أطلب تسعيرة
        </span>
      </StyledButton>
    </GridItem6>
  </GridContainer>
);


const arrayOfImages = ["cina.webp", "cinp.png", "cdtp.webp", "cins.webp", "ccino.webp", "aina.webp"];

const TrainingOrgTopics = () => (
  <OrgGridContainer>
    {
      arrayOfImages.map(a => <OrgGridItem href={`#${a.split(".")[0]}`}>
        <img src={a} alt={a} />
      </OrgGridItem>)
    }
  </OrgGridContainer>
);

export default () => {
  return (
    <Container>
      <StyledHeader collapseBreakpointClass="sm" />

      <MainFeature1
        subheading={<Subheading>الاعتماد المهني</Subheading>}
        heading="الحصول على الاعتماد المهني للابتكار      "
        buttonRounded={false}
        primaryButtonText="تواصل معنا"
        primaryButtonUrl="/contact"
        description="نعمل في مركاز على توفير اعتماد المعهد العالمي للابتكار لمراكز التدريب لدعم الشهادات الاحترافية وتحقيق أعلى معايير الابتكار للمركز مما يساهم في توافد العديد من المنظمات التي تولي اهتماما كبيرا للحصول على الشهادات التي تدعم الابتكار في منشأتها ."
        textOnLeft={false}
        imageSrc="/aintp.png"
      />
      
      <TwoColumn>
        <RightColumn>
          <Content>
            <Heading>دورات تدريبية معتمدة للمؤسسات</Heading>
            <Paragraph>معتمدة من المعهد العالمي للابتكار
              معتمدة من المؤسسة العامة للتدريب التقني والمهني</Paragraph>

              <Actions>

            <StyledButton href="/contact">
              <span></span>
              <span></span>
              <span>
                أطلب تسعيرة
              </span>
            </StyledButton>
              </Actions>
            {/* <Actions>
              <StyledButton href="/contact">
                <span></span>
                <span></span>
                <span>
                  أطلب تسعيرة
                </span>
              </StyledButton>
            </Actions> */}
          </Content>
        </RightColumn>
        <LeftColumn>
          <Content>
            <TrainingOrgTopics />
          </Content>

          {/* <Content>
            <Heading>دورات تمكين الافراد من الابتكار </Heading>
            <Paragraph>برامج تدريبية متخصصه للارتقاء بمستوي الافراد في الابتكار </Paragraph>
            <TrainingIndividualsTopics />
          </Content> */}
        </LeftColumn>
      </TwoColumn>
    </Container>
  );
};
