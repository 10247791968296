import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-full md:h-auto`;

const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-right`;

const Subheading = tw(SubheadingBase)`text-center md:text-right`;
const Heading = tw(SectionHeading)`mt-4 font-black text-right text-3xl sm:text-4xl lg:text-5xl text-center md:text-right leading-tight`;
const Description = tw.p`mt-4 text-center md:text-right text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none placeholder-gray-900 placeholder-opacity-50 transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24 `}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 bg-primary-500 text-gray-100 hocus:bg-primary-900`

const ContactInfoContainer = styled.div`
  ${tw`p-4 rounded`}
`;

const Header = styled.h2`
  ${tw`text-lg font-bold mb-4`}
`;

const ContactInfoList = styled.ul`
  ${tw`list-none`}
`;

const ContactInfoItem = styled.li`
  ${tw`flex flex-row gap-4 mb-2`}
`;

const ContactInfoLabel = styled.span`
  ${tw`font-semibold mr-2`}
`;

const ContactInfoValue = styled.span`
  ${tw`text-gray-700`}
`;
const ContainerDiv = styled.div`
  ${tw`w-full h-full py-8`}
`;

// Styled component for the iframe
const StyledIframe = styled.iframe`
  ${tw`w-full h-2/3`}
`;


// Styled component for the container
const CheckboxGroupContainer = styled.div`
  ${tw`flex flex-col grid grid-rows-2 md:grid-cols-3 sm:grid-cols-1 gap-2`}
`;
const CheckboxGroupHeader = styled.div`
  ${tw`font-bold mt-4`}
`;
// Styled component for individual checkbox label
const CheckboxLabel = styled.label`
  ${tw`flex flex-row items-center text-center text-center text-lg font-bold m-1 md:m-1 sm:m-8`}
`;

// Styled component for the checkbox input
const CheckboxInput = styled.input`
  ${tw`ml-2`}
`;

const CheckboxGroup = ({ options, onChange }) => {
  // State to track the selected checkboxes
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Function to handle checkbox selection
  const handleCheckboxChange = (option) => {
    // Toggle the selection status of the option
    const isSelected = selectedOptions.includes(option);
    const newSelectedOptions = isSelected
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];

    // Update state and call onChange callback
    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  return (
    <>
      <CheckboxGroupHeader>الخدمات المطلوبة</CheckboxGroupHeader>
      <CheckboxGroupContainer>
        {options.map((option) => (
          <CheckboxLabel key={option.value}>
            <CheckboxInput
              type="checkbox"
              checked={selectedOptions.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
            />
            {option.label}
          </CheckboxLabel>
        ))}
      </CheckboxGroupContainer>
    </>
  );
};

export default ({
  subheading = "تواصل معنا",
  heading = <>لا تتردد <span tw="text-primary-500">في التواصل</span><wbr /> معنا.</>,
  description = "يُرجى ملء الحقول المطلوبة وإرسال رسالتك، وسنعود إليك في أقرب وقت ممكن. شكرًا لتواصلكم معنا",
  submitButtonText = "إرسال الرسالة",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const options = [
    { value: 'طلب تسعيرة', label: 'طلب تسعيرة' },
    { value: 'فعاليات', label: 'فعاليات' },
    { value: 'تدريب', label: 'تدريب' },
    { value: 'تقييم', label: 'تقييم' },
    { value: ' استفسار عام', label: ' استفسار عام    ' },
    { value: 'غير ذلك', label: 'غير ذلك' },
  ];

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    subject: '',
    message: '',
    selectedOptions: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (selectedOptions) => {
    setFormData((prevData) => ({ ...prevData, selectedOptions }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Submit formData to API endpoint
      const response = await fetch('https://us-central1-mrkaz-2030.cloudfunctions.net/api/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      setLoading(false);
      if (response.ok) {
        // Handle successful form submission
        window.location.href = '/thanks';
      } else {
        // Handle error
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting form:', error.message);
    }
  };


  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <ContainerDiv>
            <StyledIframe
              loading="lazy"
              src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d45909.07272652855!2d46.64847911557947!3d24.651639559685503!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1707248342667!5m2!1sen!2sus"
              title=""
              aria-label=""
            />
            <ContactInfoContainer>
              <Header>بيانات التواصل</Header>
              <ContactInfoList>
                <ContactInfoItem>
                  <ContactInfoLabel>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"></path></svg>
                  </ContactInfoLabel>
                  <ContactInfoValue>info@mrkaz.org</ContactInfoValue>
                </ContactInfoItem>
                <ContactInfoItem>
                  <ContactInfoLabel>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>
                  </ContactInfoLabel>
                  <ContactInfoValue>966543316555+</ContactInfoValue>
                </ContactInfoItem>
                <ContactInfoItem>
                  <ContactInfoLabel>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>
                  </ContactInfoLabel>
                  <ContactInfoValue>966566332844+</ContactInfoValue>
                </ContactInfoItem>
                <ContactInfoItem>
                  <ContactInfoLabel><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995L16.9497 15.9497ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"></path></svg></ContactInfoLabel>
                  <ContactInfoValue>الرياض المملكة العربية السعودية</ContactInfoValue>
                </ContactInfoItem>
              </ContactInfoList>
            </ContactInfoContainer>
          </ContainerDiv>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            {loading && <p>جاري الارسال</p>}
            {!loading && <Form onSubmit={handleSubmit}>
              <Input
                type="email"
                required
                name="email"
                placeholder="عنوان البريد الإلكتروني"
                value={formData.email}
                onChange={handleInputChange}
              />
              <Input
                type="text"
                required
                name="name"
                placeholder="الإسم الكامل"
                value={formData.name}
                onChange={handleInputChange}
              />
              <Input
                type="text"
                name="subject"
                required
                placeholder="الموضوع"
                value={formData.subject}
                onChange={handleInputChange}
              />
              <CheckboxGroup options={options} onChange={handleCheckboxChange} />
              <Textarea
                name="message"
                required
                placeholder="ضع رسالتك هنا"
                value={formData.message}
                onChange={handleInputChange}
              />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
