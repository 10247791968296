import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { LogoLink } from "../headers/light.js";
import { StyledButton } from "./BackgroundAsImage.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const Container = tw.div`relative -mt-8 -mx-8 pt-8 bg-white`;
const TwoColumn = tw.div`pt-2 pb-20 px-12 flex justify-between items-center flex-col lg:flex-row`;
// const LeftColumn = tw.div``;
const LeftColumn = tw.div`
  relative
  w-full
  ml-8 mr-8 lg:w-2/5 xl:pl-10 py-2 bg-cover
  bg-center 
`;

const Overlay = tw.div`
  absolute
  inset-0
  bg-black
  opacity-25
`;

const RightColumn = styled.div`
  ${tw`xl:ml-24 h-96 lg:h-auto lg:w-3/5 lg:flex-1`}
`;

const Content = tw.div`mt-2 lg:mt-2 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-6xl text-primary-500 sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none`;
const Paragraph = tw.p`my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-lg xl:text-lg leading-loose`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

export default ({
  heading = (
    <>
      <wbr />
      <br />
      <span tw="text-primary-500">ابداع – تمكين – ابتكار</span>
    </>
  ),
  LeftComponent = (<> </>),
  description = "نسعى دائما في مركاز على خلق فعاليات تساهم في تعزيز التفكير الإبداعي الجماعي من خلال تمكين المشاركين من أدوات ومنهجيات الابداع والابتكار وتبادل الأفكار والخبرات وتطوير قدراتهم الإبداعية والابتكارية  لصناعة حلول مبتكرة ترفع من جودة الخدمات في المنظمات  ",
  primaryActionText = "هل لديك فعالية!  تواصل معنا ",
}) => {
  return (
    <Container>
      <StyledHeader collapseBreakpointClass="sm" />
      <TwoColumn>
        <RightColumn>
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>

            <Actions>
              <StyledButton href="/contact">
                <span></span>
                <span></span>
                <span>
                  {primaryActionText}
                </span>
              </StyledButton>
            </Actions>
          </Content>
        </RightColumn>
        <LeftColumn  >
          {LeftComponent}
        </LeftColumn>
      </TwoColumn>
    </Container>
  );
};
